<template>
  <div class="animated fadeIn">
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="this.$config.LOADER.color"
      :opacity="this.$config.LOADER.opacity"
      :background-color="this.$config.LOADER.backgroundColor"
      :z-index="10000"
    ></loading>
    <b-nav tabs style="margin-bottom: -1px;">
      <b-nav-item
        @click="changeType(0, 10, 365000)"
        :active="selectedTypeCibcode == 0"
        >Бүгд</b-nav-item
      >
      <b-nav-item
        :key="index"
        v-for="(item, index) in loanClasses"
        @click="changeType(item.cibCode, item.minDay, item.maxDay)"
        :active="selectedTypeCibcode == item.cibCode"
        >{{ item.name }}</b-nav-item
      >
    </b-nav>
    <b-row>
      <b-col sm="12">
        <c-table
          selectable
          :url="'CustomerWebService/get_overdue_loan_list'"
          :per-page="10"
          :fields="columns"
          :columns="[
            'accountNumber',
            'lastname',
            'firstname',
            'register',
            'phone',
            'expirtDate',
            'loanBalance',
          ]"
          :filename="'Хугацаа хэтэрсэн зээл'"
          hover
          bordered
          fixed
          caption=""
          :startDate="minDate"
          :endDate="maxDate"
          :selectSubmitFunction="confitCib"
          :selectedButton="{ text: 'Илгээх', icon: 'fa fa-send' }"
        ></c-table>
      </b-col>
    </b-row>

    <div>
      <b-modal ref="my-modal" size="xl" @ok="sentToCib">
        <template slot="modal-title">
          Баталгаажуулалт
        </template>
        <div class="d-block">
          <b-table
            stacked="md"
            hover
            bordered
            small
            responsive="sm"
            :items="selected"
            :fields="columns.slice(1)"
            no-local-sorting
          >
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(nicename)="data">
              {{ data.item.lastname.substr(0, 1) + "." + data.item.firstname }}
            </template>
            <template v-slot:cell(overdueDay)="data">
              <strong class="text-danger">
                {{ data.item.overdueDay }} өдөр</strong
              >
            </template>
          </b-table>
          <p class="text-danger mb-0">
            Та дээрх зээлийн мэдээллийг
            <strong>"Зээлийн мэдээллийн сан"</strong> - руу ангилал өөрчлөх
            хүсэлт явуулахдаа итгэлтэй байна уу?
          </p>
        </div>
        <template slot="modal-footer"></template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment, { min } from "moment";
export default {
  name: "OverdueLoan.list",
  data: function() {
    return {
      columns: [
        { label: "", key: "checkbox" },
        { label: "№", key: "index", class: "text-center" },
        // { label: 'Данс', key: 'accountNumber' },
        {
          label: "Нэр",
          key: "nicename",
          sortable: true,
          sort_key: "firstname",
        },
        {
          label: "Регистр",
          key: "register",
          sortable: true,
          sort_key: "register",
        },
        { label: "Утас", key: "phone", sortable: true, sort_key: "phone" },
        {
          label: "Дууссан хугацаа",
          key: "expiryDate",
          sortable: true,
          sort_key: "expiry_date",
        },
        { label: "Хэтэрсэн хоног", key: "overdueDay", sortable: false },
        {
          label: "Авсан зээлийн дүн",
          key: "loanAmount",
          sortable: true,
          sort_key: "loan_amount",
          class: "text-right",
        },
        {
          label: "Зээлийн үлдэгдэл дүн",
          key: "loanBalance",
          sortable: true,
          sort_key: "loan_balance",
          class: "text-right",
        },
        { label: "ЗМС", key: "lastLoanClass" },
      ],
      selected: [],
      loanClasses: [],
      minDate: moment()
        .subtract(365000, "days")
        .format("YYYY-MM-DD"),
      maxDate: moment()
        .subtract(1, "days")
        .format("YYYY-MM-DD"),
      selectedTypeCibcode: 0,
      isLoading: false,
    };
  },
  created() {
    this.getLoanClass();
  },
  methods: {
    getLoanClass: function() {
      this.$http
        .get(this.$config.API_URL + "AdminWebService/get_reference", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.$data.loanClasses = response.body.data;
          },
          (response) => {}
        );
    },
    changeType: function(cibcode, minDay, maxDay) {
      this.$data.selectedTypeCibcode = cibcode;
      this.$data.minDate = moment()
        .subtract(maxDay, "days")
        .format("YYYY-MM-DD");
      this.$data.maxDate = moment()
        .subtract(minDay, "days")
        .format("YYYY-MM-DD");
    },
    confitCib: function(ids) {
      this.$data.selected = ids;
      this.$refs["my-modal"].show();
    },
    sentToCib: function() {
      var _ids = [];
      for (var i in this.$data.selected) {
        _ids.push(this.$data.selected[i].id);
      }
      this.isLoading = true;
      this.$http
        .post(
          this.$config.APP_URL + "CompanyWebService/change_loan_class",
          { loanAccountIds: JSON.stringify(_ids) },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
  },
};
</script>
